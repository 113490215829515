<template>
  <dash-page-new :title="$t('Dashboard')"
            icon="mdi-view-dashboard-outline"
            no-side >


      <v-row class="mt-6">
        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3"  v-for="(business,i) in businesses" :key="i">


          <v-hover #default="{hover}">
            <v-sheet
                :style="`border-left : 8px solid ${wsATTENTION};` + (hover ? 'box-shadow: 4px 4px 4px #DEE8EE !important;' : '')"
                :color="wsLIGHTCARD"
                class="d-flex pointer align-center pa-6 px-5 "
                style="transition: all 0.2s ease; border-radius : 8px; position : relative ; "
                @click="$router.push(localeLink('dashboard/' + business.alias));checkAvalon(business)"
                :elevation="hover ? 1 : 0"
            >
              <div style="width: 100%" >
                <h5  :style="`color : ${wsACCENT}`"   class=" font-weight-regular d-flex align-center mb-3 px-1">
                  <span>{{ $t('PaymentPlan') }} : {{ business.business_plan_name }}</span>
                </h5>
                <h4 class="shorten-text px-1" :style="`color : ${wsDARKER}`" style="font-size: 16px" >{{ business.name }}</h4>

                <v-hover #default="{hover}">
                  <ws-link
                      :to="!business.domain ? localeLink(business.alias) : null"
                      :href="business.domain ? ('https://' + business.domain)  : null"
                  >
                      <v-sheet
                          @click.stop="checkAvalon(business)"
                          :color=" hover ? (wsACCENT + '33') : 'transparent'"
                          class="pa-1 pointer wsRoundedLight"
                          style="transition: all 0.3s ease"
                          width="95%"
                      >
                        <h5  :style="`color : ${!hover ? wsACCENT : wsDARKER}`"   class="font-weight-regular d-flex align-center">
                          <span >{{ business.domain || `westudy.ua/${ business.alias }` }}</span>
                          <v-icon small :color="wsACCENT">mdi-open-in-new</v-icon>
                        </h5>
                      </v-sheet>
                  </ws-link>

                </v-hover>

                <v-hover v-if="business.is_new_avalon" #default="{hover}">
                  <ws-link
                      @click.stop
                      :to="!business.domain ? localeLink(business.alias + '/editor') : null"
                      :href="business.domain ? ('https://' + business.domain + ('/editor')) : null"
                  >
                  <v-sheet
                      @click.stop
                      :color=" hover ? (wsACCENT + '33') : 'transparent'"
                      class="pa-1 pointer wsRoundedLight"
                      style="transition: all 0.3s ease"
                      width="95%"
                  >
                      <h5 :style="`color : ${!hover ? wsACCENT : wsDARKER}`"   class="font-weight-regular d-flex align-center">
                        <span >{{ $t('EditWebsite')}}</span>
                      </h5>
                  </v-sheet>
                  </ws-link>

                </v-hover>

                <h5  :style="`color : ${wsACCENT}`"   class="font-weight-regular mt-1 px-1 d-flex align-center">
                  {{ $t('ActiveTill') }}:  {{ MONTH_DAY_TIME( business.date_active , false) }}
                </h5>

              </div>

              <div :style="`opacity : ${hover ? 1 : 0}`" style="transition: all 0.3s ease;"  class="triangle">
              </div>
              <v-icon :style="`opacity : ${hover ? 1 : 0}`"  color="#ffffff" style="position: absolute; transition: all 0.3s ease; right: 4px; bottom: 8px">mdi-arrow-right</v-icon>

            </v-sheet>
          </v-hover>


        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3"  >
          <v-sheet @click="displayNew = true" dark v-ripple  :color="wsACCENT" class="wsRoundedHalf pa-6 d-flex align-center fill-height pointer">
            <v-icon>mdi-plus</v-icon>
            <h3 class="font-weight-light">{{ $t('AddBusinessAccount') }}</h3>
          </v-sheet>
        </v-col>

      </v-row>

    <registerBusiness v-model="displayNew" />

  </dash-page-new>
</template>

<script>

import registerBusiness from "@/components/UI/components/registerBusiness";
import {mapActions} from "vuex";
export default {
  name: "education",
  components : {
    registerBusiness
  },
  props : ['user_id'],
  data() {
    return {
      businesses : [],
      displayNew : false,
    }
  },
  methods : {
    ...mapActions('business', [ 'GET_USER_BUSINESSES' ] ),

    checkAvalon(business) {
      if (!business.is_new_avalon && !this.$store.state.$NEW_AVALON ) {
        setTimeout(()=> {
          location.reload()
        },700)
      }
    }


  },
  async mounted() {
    this.businesses = await this.GET_USER_BUSINESSES() ?? []
  }
}
</script>

<style scoped lang="scss">
.triangle {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-bottom: 60px solid #ED8A58;
  border-bottom-right-radius: 8px;
  transform: translateX(50%) translateY(50%);
}
</style>